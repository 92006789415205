<template>
  <div class="scan-delivery-result-container" v-if="showPage">
    <div class="space box-p-10"></div>
    <div class="card">
      <div class="card-line" style="margin-bottom: 20px">
        <cell-v3 :hide-icon="true">
          <div class="main-title" slot="title">
            发货点：{{ orderInfo.pickInfo.store_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">订单编码：</div>
          <div class="values">{{ orderInfo.pickInfo.flow_up_code }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联 系 人 ：</div>
          <div class="values">{{ orderInfo.pickInfo.lianxiren }}</div>
        </cell-v3>
      </div>
      <div
        class="card-line"
        style="border-bottom: 1px solid #ebebeb; padding-bottom: 15px"
      >
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联系电话：</div>
          <div class="values">{{ orderInfo.pickInfo.mobile }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">配 送 员 ：</div>
          <div class="values">{{ orderInfo.delivery.dc_name }}</div>
        </cell-v3>
      </div>
      <div
        class="card-line"
        style="border-bottom: 1px solid #ebebeb; padding-bottom: 15px"
      >
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联系电话：</div>
          <div class="values">{{ orderInfo.delivery.dc_mobile }}</div>
        </cell-v3>
      </div>
      <div class="card-line" style="margin-bottom: 20px">
        <cell-v3 :hide-icon="true">
          <div class="main-title" slot="title">
            自提点：{{ orderInfo.point.site_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">收 货 员 ：</div>
          <div class="values">
            {{ orderInfo.point.site_point_receive_user_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联系电话：</div>
          <div class="values">
            {{ orderInfo.point.site_point_receive_user_mobile }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">收货时间：</div>
          <div class="values">
            {{ orderInfo.point.site_point_receive_time }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line" style="margin-top: 20px">
        <cell-v3 :hide-icon="true">
          <div class="scan-button" @click="hide = !hide">
            订单详情
            <img
              v-if="hide"
              :src="require('@/static/image/icons/arrowUp.png')"
            />
            <img v-else :src="require('@/static/image/icons/arrowDown.png')" />
          </div>
        </cell-v3>
      </div>

      <div class="goods-list" v-show="hide">
        <div
          class="goods"
          v-for="(goodItem, goodIndex) in orderInfo.goodList"
          :key="goodIndex"
        >
          <goods-v4
            :img-url="goodItem.cover"
            :number="goodItem.total"
            :title="goodItem.title"
          />
        </div>
      </div>

      <div class="button-container">
        <div class="button">
          <div class="button-inner" @click="makeSure">
            {{ showName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CellV3 from "../../template/community/cell/cellV3";
import GoodsV4 from "../../template/community/goods/GoodsV4";

export default {
  name: "scan-delivery-result",
  components: { GoodsV4, CellV3 },
  data() {
    return {
      showPage:false,
      hide: false,
      doId: 0,
      code: "",
      site_point_status: 0,
      statusName: {
        0: "确认收货",
        1: "已收货",
        2: "已发货",
      },
      orderInfo: [],
    };
  },
  created() {
    document.title = "配送员订单详情";
    if (typeof this.$route.query.code != "undefined") {
      this.code = this.$route.query.code;
    }
    if (typeof this.$route.query.id != "undefined") {
      this.doId = parseInt(this.$route.query.id);
    }
    this.getOrderInfo();
  },
  methods: {
    gotHome() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({ type: "navigateBack" });
      } else {
        this.$router.push({ path: "self-extract-home" });
      }
    },
    async getOrderInfo() {
      try {
        let query = null;

        let params = {
          id: this.doId,
          code: this.code,
          from_type: 1, //1 骑手
        };

        //this.isHasMore=false;
        query = await this.$api.community.points.pointOrderInfo(params);
        this.orderInfo = query.data;
        let site_point_status = this.orderInfo.point.site_point_status;
        this.showName = this.statusName[site_point_status];
        this.showPage = true
      } catch (error) {
        let self = this;
        self.$dialog({
          title: "温馨提示",
          content: error.message || error,
          closeBtn: false, //显式右上角关闭按钮
          noCancelBtn: true,
          onOkBtn() {
            self.$dialog.closed();
            self.$platform.wxsdk.wxRoute({ type: "navigateBack" });
          },
        });
      }
    },
    async makeSure() {
      if (this.orderInfo.point.site_point_status == 2) {
        this.$toast.fail("该订单已出库");
        return true;
      }
      if (this.orderInfo.point.site_point_status == 1) {
        this.$toast.fail("该订单已经签收");
        return true;
      }
      try {
        let query;
        let params = {
          id: this.orderInfo.point.id,
        };
        query = await this.$api.community.points.makeSureReceive(params);
        let self = this;
        this.$dialog({
          title: "温馨提示",
          content: "货物已签收",
          closeBtn: false, //显式右上角关闭按钮
          noCancelBtn: true,
          onOkBtn() {
            self.$dialog.closed();
            self.$platform.wxsdk.wxRoute({ type: "navigateBack" });
          },
        });
        this.orderInfo.point.site_point_status = 1;
      } catch (error) {
        let self = this;
        self.$dialog({
          title: "温馨提示",
          content: error.message || error,
          closeBtn: false, //显式右上角关闭按钮
          noCancelBtn: true,
          onOkBtn() {
            self.$dialog.closed();
            self.$platform.wxsdk.wxRoute({ type: "navigateBack" });
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.scan-delivery-result-container {
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;

  .space {
    height: 10px;
  }

  .search-container {
    display: flex;
    justify-content: center;

    .search {
      width: 345px;
      height: 32px;
    }
  }

  .card {
    background: #fff;
    margin: 15px;
    padding: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 59px;

    .main-title {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .order-status {
      color: #1c741d;
      font-size: 16px;
      font-weight: bold;
    }

    .title,
    .values {
      color: #333333;
      font-size: 14px;
    }

    .card-line {
      margin-bottom: 9px;

      .scan-button {
        color: #f46a17;
        font-size: 14px;

        img {
          width: 14px;
          height: 14px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        color: #f46a17;

        img {
          margin-right: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }

    .bottom-border {
      padding: 0 0 15px 0;
      border-bottom: #ebebeb solid 1px;
      margin-bottom: 20px;
    }

    .goods-list {
      width: 100%;
      border-top: #ebebeb solid 1px;
      padding-bottom: 15px;
      margin-top: 15px;

      .goods {
        margin-top: 15px;
      }
    }

    .money {
      margin-top: 15px;
      font-size: 16px;
      color: #c10000;
      font-weight: bold;
    }
  }
}

.button-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  .button {
    flex: 1;
    height: 44px;
    font-size: 14px;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
}
</style>
